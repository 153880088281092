import { collapseDrawer, expandDrawer } from '../utils/animation';
import dispatchEvent from '../utils/dispatch-event';
import EVENT from '../enums/event';

const selectors = {
  button: '[data-accordion-drawer-button]',
  content: '[data-accordion-drawer-content]',
  header: '[data-accordion-drawer-header]',
};

class AccordionDrawer {
  /**
   * @param {Element} element
   */
  constructor(element) {
    this.element = element;

    this.button = this.element.querySelector(selectors.button);
    this.content = this.element.querySelector(selectors.content);
    this.header = this.element.querySelector(selectors.header);

    this.init();
  }

  init() {
    this.registerEventListeners();
  }

  registerEventListeners() {
    this.button.addEventListener('click', () => {
      this.toggle();
    });

    window.addEventListener('resize', () => {
      this.emitCloseAllEvent();
    });
  }

  toggle() {
    const expanded = this.button.getAttribute('aria-expanded');

    if (expanded === 'true') {
      this.close();
    } else {
      this.open();
    }
  }

  close() {
    collapseDrawer(this.content);

    this.button.setAttribute('aria-expanded', 'false');
    this.element.dataset.state = 'closed';
    this.content.dataset.state = 'closed';
    this.content.style.minHeight = '0px';
    this.content.style.height = '0px';
  }

  open() {
    expandDrawer(this.content);

    this.button.setAttribute('aria-expanded', 'true');
    this.element.dataset.state = 'open';
    this.content.dataset.state = 'open';

    this.emitCloseAllEvent();
  }

  emitCloseAllEvent() {
    dispatchEvent(EVENT.ACCORDION.DRAWERCLOSED, this.element, {
      detail: {
        drawer: this,
      },
    });
  }
}

export default AccordionDrawer;
